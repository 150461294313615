import { BASE_COLORS, FONT_SIZE, FONT_WEIGHT, LAYOUT, media } from '@app/styles/themes/constants';
import { Col, Row, Typography, Modal, DatePicker, Layout, Menu, Button, theme, Alert, Radio } from 'antd';
import styled, { css } from 'styled-components';
import { makeStyles } from '@mui/styles';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { Input as CommonInput } from '@app/components/common/inputs/Input/Input';
import { MaterialReactTable } from 'material-react-table';
import { CalendarOutlined, LoadingOutlined } from '@ant-design/icons';
import { BaseTypography } from '@app/components/common/BaseTypography/BaseTypography';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';
import ReactMarkdown from "react-markdown";
import MDEditor from "@uiw/react-md-editor";
import { themeObject } from '@app/styles/themes/themeVariables';
import { Box, Tab } from '@mui/material';
import { AnimatedEllipsisIcon } from '@app/components/common/icons/AnimatedEllipsisIcon';

export const WrapperRow = styled(Row)`
  margin-bottom: 5px;
`;

export const Title = styled(Typography.Title)`
  &.ant-typography {
    margin-bottom: 0;

    font-family: 'Segoe UI Regular', 'Poppins', sans-serif;
    font-size: ${FONT_SIZE.lg};
    color: var(--primary-color);

    @media only screen and ${media.xl} {
      font-family: 'Segoe UI Regular', 'Poppins', sans-serif;
      font-size: ${FONT_SIZE.lg};
    }
  }
`;

export const PageTitle = styled(Typography.Title)`
  &.ant-typography {
    font-family: 'Segoe UI Regular', 'Poppins', sans-serif;
    font-size: ${FONT_SIZE.xl};
    font-weight: ${FONT_WEIGHT.bold};
    text-decoration: underline;
    color: var(--primary-color);

    @media only screen and ${media.xl} {
      font-size: ${FONT_SIZE.xxl};
    }
  }
`;

export const Paragraph = styled(Typography.Paragraph)`
  &.ant-typography {
    font-family: 'Segoe UI Regular', 'Poppins', sans-serif;
    font-size: ${FONT_SIZE.md};
    color: var(--primary-color);

    @media only screen and ${media.xl} {
      font-size: ${FONT_SIZE.md};
    }
  }
`;

export const SearchColumn = styled(Col)`
  padding: ${LAYOUT.desktop.paddingVertical} ${LAYOUT.desktop.paddingHorizontal};
`;

export const IconContainer = styled(Col)`
  width: 50%;
  display:flex; 
  justify-content:center;
`

export const BreadcrumbContainer = styled.div`
  display:inline-flex;
  justify-content:center;
  align-items:center;
`;

export const Tag = styled.div`
    width:75vw;
    background-color: ${BASE_COLORS.transparent};
    border: 1px solid var(--textLight);
    border-radius: 10px;
    padding: 1% 2% 1% 2%;
    display:flex;
    flex-direction:column;
    margin-bottom:5px;

    @media only screen and ${media.sm} {
      flex-direction:row;
      align-items:center;
    }
`;

export const NoBorderTag = styled(Tag)`t
    height:max-content;
    border: 0px;
    padding: 1% 5% 1% 0%;
    align-items: center;
`;

export const StackedTag = styled(Tag)`
    padding: 0px;
    align-items: flex-start;
`;

export const NotificationTag = styled(Tag)`
    width:100%;
    max-height: 65px;
    border: 0px solid var(--textLight);
    border-top: 1px solid var(--textLight);
    border-radius: 0px;
    padding: 5px;
    align-items: flex-start;
`;

export const HeaderText = styled.div`
    font-size: ${FONT_SIZE.md};
    font-weight: ${FONT_WEIGHT.bold};
    color: var(--textLight);
`;

export const SectionTitleText = styled(HeaderText)`
  font-size: ${FONT_SIZE.lg}; 
    color: #505050;
`;

export const BlackHeaderText = styled(HeaderText)`
    font-size: 0.875rem; 
    line-height: 1.25rem;
    font-weight: ${FONT_WEIGHT.medium};
    color: #505050;
    margin-left: 5px;
`;

export const TableFilterHeader = styled(BlackHeaderText)`
    font-size: ${FONT_SIZE.md};
    margin-right: 10px;
`;

export const SummaryCardText = styled(BlackHeaderText)`
    font-size: 4rem;
    line-height: 4.75rem;
    font-weight: ${FONT_WEIGHT.bold};
`;
export const SummaryCardFooterText = styled.div`
    font-style:italic;
    font-size: ${FONT_SIZE.xs};
    color: ${themeObject['light'].primary};
    font-weight:${FONT_WEIGHT.regular};
`;
export const SummaryCardIcon = styled(BlackHeaderText)`
  font-size: 2.5rem;
  margin-right: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const SummaryCardFilterIcon = styled(BlackHeaderText)`
  margin-right: 8px;

  &:hover {
    cursor: pointer;
  }
`;

export const UnlimitedServiceText = styled(SummaryCardText)`
    color: #699F4C;
    font-size: ${FONT_SIZE.xxl};
`;

export const OverdraftText = styled(SummaryCardText)`
    font-size: 1.5rem;
    color: #ED1818;
    line-height: 25px; 
`;

export const OverdraftFormText = styled(SummaryCardText)`
    font-size: 1.5rem;
    color: #ED1818;
`;

export const OverdraftNoteText = styled(OverdraftText)`
    font-size: 1rem;
`;

export const InvalidText = styled(OverdraftFormText)`
    font-size: ${FONT_SIZE.xs};
    font-weight: ${FONT_WEIGHT.light};
`;

export const WarningText = styled(OverdraftFormText)`
font-size: ${FONT_SIZE.xs};
line-height: 1.25rem;
font-weight: ${FONT_WEIGHT.light};
color: #ff6700;
text-align: right;
`;

export const LateText = styled(OverdraftNoteText)``;
export const EscalationText = styled(OverdraftNoteText)``;
export const TerminationText = styled(EscalationText)`
color:red;
line-height: 25px;
`;
export const AssignedText = styled(OverdraftNoteText)`
    color:#699F4C;
`;

export const BodyText = styled(BlackHeaderText)`
    font-size: ${FONT_SIZE.xs};
`;

export const CardHeaderText = styled(BodyText)`
  color: ${BASE_COLORS.white};
  font-weight:${FONT_WEIGHT.bold};
  font-size: ${FONT_SIZE.md};
  margin-bottom:5px;
`;

export const CardSubText = styled(BodyText)`
  color: ${BASE_COLORS.white};
  margin-bottom:5px;
`;

export const SpacedSpan = styled.span`
  margin-left:5px;
`;

export const PrepaidText = styled(BodyText)`
    color: #ED0579;
    font-weight: ${FONT_WEIGHT.bold};
`;

export const PrepaidHighlightText = styled(PrepaidText)`
    background-color:${themeObject['light'].primary};
    color: ${BASE_COLORS.white};
`;

export const FAQSectionText = styled(HeaderText)`
    margin-bottom:10px;
    font-weight:${FONT_WEIGHT.bold};
`;

export const FAQQuestionText = styled(BlackHeaderText)`
  color: #f7931d;
  font-size:${FONT_SIZE.md}
`;

export const FAQAnswerText = styled(FAQQuestionText)`
  color: #505050;
  font-weight:${FONT_WEIGHT.regular}
`;

export const FAQHighlightedText = styled.span`
  font-weight:${FONT_WEIGHT.bold}; 
  text-decoration:underlined;
`;

export const LetterHeaderText = styled(BodyText)`
    font-size: ${FONT_SIZE.lg };
`;

export const PlainTableText = styled(BodyText)`
    color: var(--textLight);
    font-weight:${FONT_WEIGHT.regular};
`;

export const LetterDetailText = styled(PlainTableText)`
    font-size: ${FONT_SIZE.md};
`;

export const TagText = styled(PlainTableText)`
    margin-top:2;
`;

export const LetterItalicText = styled.text`
    font-size: ${FONT_SIZE.md};
    font-style: italic;
`;

export const LetterSupplementary = styled(LetterDetailText)`
    text-decoration-line: underline;
    text-decoration-style: dotted;
`;

export const CrossOutText = styled.s`
    color: var(--textLight);
    font-size: ${FONT_SIZE.xs};
    font-weight:${FONT_WEIGHT.regular};
`;

export const ItalicText = styled.text`
    font-style:italic;
    font-size: ${FONT_SIZE.xs};
    color: ${BASE_COLORS.darkgrey};
    font-weight:${FONT_WEIGHT.regular};
`;

export const ItalicTextWithIcon = styled(ItalicText)`
    display: flex;
    align-items: center;
`;

export const IconLabelText = styled(BodyText)`
    font-size: ${FONT_SIZE.xs};
    color: ${themeObject['light'].primary};
    padding-right:30px;
`;

export const NotificationHeaderText = styled(HeaderText)`
    font-size: ${FONT_SIZE.xs};
`;

export const DetailText = styled.div`
    font-size: ${FONT_SIZE.md};
    font-weight: ${FONT_WEIGHT.regular};
    color: var(--textLight);
`;

export const NotificationDetailText = styled(DetailText)`
    font-size: ${FONT_SIZE.xxs};
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
`;

export const ImageContainer = styled.div`
    width: 80%;
    height: 80%;
    max-width: 150px;
    max-height: 150px;
    border-radius: 25%;

    @media only screen and ${media.sm} {
      width: 100%;
      height: 100%;
    }
`;

export const Container = styled(Col)`
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    overflow: hidden;
    display: flex;
    flex-direction: column;

`;

export const NameTagContainer = styled(Container)`
    display:flex;    
    flex-direction: column;
    align-items:center;
    justify-content:center;
    border: 1px solid var(--textLight);
    border-radius: 10px;

      @media only screen and ${media.sm} {
      width: 100%;
      height: 100%;
      flex-direction: row;  
      justify-content:left;
    }
`;

export const OverdraftContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 170px;
`;

export const ActualConsumedContainer = styled(OverdraftContainer)`
    width: 200px;
`;

export const TableTagsContainer = styled.div`
    display:flex;
    margin: 10px 10px 10px 0px;
    flex-wrap:wrap;
`;

export const DefaultSquare = styled.div`
    width:10rem;
    height:6rem;
    background-color: var(--textLightGrey);
    border-radius: 10px;
   }
`;

export const DecoratedDefaultSquare = styled(DefaultSquare)`
    width: 6rem;
    border-radius: 10px 0px 0px 0px;
    position:relative;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      width: 0; 
      height: 0; 
      display: block;
      border-left: 35px solid ${BASE_COLORS.transparent};
      border-bottom: 35px solid ${BASE_COLORS.transparent};
      border-top: 35px solid var(--primary-color);
      z-index:0;
   }
`;

export const Label = styled.label`
    color:${BASE_COLORS.white};
    font-weight: ${FONT_WEIGHT.bold};
`;

export const PositionBoundary = styled.div`
    transform: translateX(-50%);
    display: inline-block;
    position: absolute;
    top: 15%;
    left: 10%;
`;
export const EditText = styled.text`
    color: ${BASE_COLORS.white};
    font-size: ${FONT_SIZE.xs};
    font-weight: ${FONT_WEIGHT.bold};
    position: absolute;
    top: 35%;
    left: 30%;
`;

export const ScrollContent = styled.div`
    height: 100%;
    width: 100%;
    overflow-y: auto;
    padding-right: 20px;
`;

export const useStyles = makeStyles({
  indicator: {
    background: "none" // remove MUI indicator
  },
  tabpanel: {
    "& .MuiTypography-root": {
      display: "flex",
    }
  },
  tabs: {
    "& .MuiButtonBase-root": {
      fontSize: FONT_SIZE.md,
      fontWeight: FONT_WEIGHT.bold,
      color: `${themeObject["light"].textGrey}`,
      borderBottom: `${BASE_COLORS.white}`,
      textTransform: 'none',
    },
    "& .MuiTab-root.Mui-selected": {
      color: `${themeObject["light"].primary}`,
      borderColor: `${BASE_COLORS.white}`,
    },
  },
  tab: {
    "& .MuiTouchRipple-root": {
      display: 'none',
      borderBottom: 'none',
    },
  }
})


export const Select = styled.select`
  width:55%;
  background: #F8FBFF;
  border: 1px solid var(--textLight);
  border-radius: 5px;
`;

export const WideSizeSelect = styled(Select)`
  width:75%;
`;

export const PopUpSelect = styled(Select)`

width:100%;
background: ${BASE_COLORS.white};
padding: 15px 10px;
border-radius: 10px;
font-size:16px;
}
`;

export const Option = styled.option`
  margin: 40px;
  background: #F8FBFF;
  color: var(--textLight);
`;

export const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  margin-bottom:5px;
`;

export const TableTitle = styled.div`
  text-align: left;
  color: var(--textLight);
  font-size: 1rem;
  font-family: 'Segoe UI Regular', 'Poppins', sans-serif;
  margin-top: 15px;
  margin-bottom: 2px;
`;


export const TableHeader = styled.th`
  color: var(--textLight);
  font-size: 1rem;
  font-weight: ${FONT_WEIGHT.medium};
  font-family: 'Segoe UI Bold', 'Poppins', sans-serif;
  text-align: center;
  padding: 2px;
  border: 1px solid ${BASE_COLORS.transparent};
  text-align: left;
  background-color: ${BASE_COLORS.transparent};
`;

export const HalfTableHeader = styled(TableHeader)`
  width:50%;
`;

export const CenteredTableHeader = styled(TableHeader)`
  text-align: center;
`;

export const TableData = styled.td`
  border: 1px solid ${BASE_COLORS.transparent};
  text-align: center;

  &:first-child {
    text-align: left!important;
  }
  
`;

export const TableText = styled.text`
  color: var(--textLight);
  font-size: 1rem;
  text-align: center;
  background-color: ${BASE_COLORS.transparent};
  border: none;
  width: 91%;
  padding: 2px 2px;
  font-family: 'Segoe UI Regular', 'Poppins', sans-serif;
`;


export const Input = styled.input`
    color: var(--textLight);
    font-size: 1rem;
    text-align: center;
    background-color: ${BASE_COLORS.transparent};
    border: 1px solid var(--textLight);
    border-radius: 5px;
    width: 91%;
    padding: 2px 2px;
    font-family: 'Segoe UI Bold', 'Poppins', sans-serif;

    &:hover {
    outline: 1px solid var(--primary-color);
    border: 1px solid var(--primary-color);
    }
  
    &:focus {
    outline: 1px solid var(--primary-color);
    border: 1px solid var(--primary-color);
    }
`;

export const ModalForm = styled(Modal)`
    color: var(--primary-color);

    & .ant-modal-footer {
      display:flex;
      justify-content:end;
    }
`;

export const ModalInput = styled(Input)`
    width: 50%;
    display:flex;
    flex-basis: 100%;
`;

export const MaterialReactTableContainer = styled.div`
    width: 100vw;
    height: 500px; 
    padding-right:10%;
    overflowX: auto;
    overflowY: auto;
`;

export const CustomizedMaterialReactTable = styled(MaterialReactTable)`
    & tr > th:first-child{
      font-weight: lighter;
    }
`;
export const FormItem = styled(BaseForm.Item)`

  margin-bottom: 0.75rem;

  & .ant-form-item-explain-error {
    font-size: ${FONT_SIZE.xs};
  }

  & .ant-form-item-label {
    padding: 0 0 0px;
  }

  & .ant-form-item-label > label {
    font-weight:${FONT_WEIGHT.semibold};
    color: #505050;
  }

  & label {
    font-size: ${FONT_SIZE.xs};
    line-height: 1.25rem;
    color: #000000;
  }

  &.ant-form-item-has-feedback .ant-input-affix-wrapper .ant-input-suffix {
    padding-right: 1.5rem;
  }

  & .ant-input{
    border: 2px solid var(--primary-color);
    border-radius: 25px;
  }

  & .ant-select-selection-item {
    height:2.5rem;
    max-height:30px;
  }

`;

export const GlobalSearchItem = styled(FormItem)`
& .ant-form-item-control-input{
  width:300px;
  padding: 10px 10px 0px 0px;
}
`;

export const CurrencyFormItem = styled(FormItem)`
& .ant-input{
  border: 2px solid transparent;
}
`;

export const MarkdownFormItem = styled(FormItem)`
@media only screen and ${media.md} {
  display:flex;
}
`;

export const FormInput = styled(CommonInput)`
  color: var(--text-main-color);
  background: ${BASE_COLORS.transparent};
  box-shadow:none;
  border: 2px solid var(--primary-color);
  border-radius: 25px;
  &:hover{
    border: 2px solid var(--primary-color);
  }
  &:focus{
    border: 2px solid var(--primary-color);
  }
`;

export const NumberInput = styled(FormInput)`
  width: 20vw;
  height: 40px;
`;

export const PriceList = styled(BaseForm.Item)`
  & label {
    font-size: ${FONT_SIZE.md};
    font-weight: ${FONT_WEIGHT.semibold};
  }
`;

export const ChargeTypeSuggestion = styled(PriceList)`
  & label {
    font-color: #505050;
  }

`;
export const Text = styled.div`
  color: var(--primary-color);
  font-size: ${FONT_SIZE.md};
  font-weight: ${FONT_WEIGHT.semibold};
`;

export const List = styled.ul`
  list-style-type: none;
`;

export const ListItem = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 0.8rem;
`;

export const Price = styled.div`
  color: var(--text-main-color);
  font-size: ${FONT_SIZE.md};
  font-weight: ${FONT_WEIGHT.semibold};
  margin-left: 0.5rem;
`;

export const Hint = styled(Price)`
  display:flex;
  align-items:center,
`;

export const PaymentSearch = styled(FormInput)`
@media only screen and ${media.md} {
  width:450px;
}

width:70vw;

.ant-input-group-addon:first-child{
  min-width: 0rem!important;
  width: 5px;
}

.ant-input-group-addon{
  background-color:transparent!important;
  border:0px!important;
}

.ant-input{
  border:0px!important;
}

.ant-input::focus{
  border:0px!important;
}
`;

export const TicketContainer = styled.div`
border: 1px solid var(--textLightGrey);
border-radius: 5px;
margin-bottom: 15px;
padding: 15px;
`;

export const TicketIndicator = styled.div`
background-color: var(--primary-color);
border-radius: 5px;
color: ${BASE_COLORS.white};
font-weight: bold;
padding: 5px;
align-self: center;

&:hover{
  cursor:pointer;
}
`;

export const PlusMinusIndicator = styled(TicketIndicator)`
background-color: ${BASE_COLORS.white};
-webkit-text-stroke: 1px var(--primary-color);
font-size: 1.5rem;
`;

export const IndicatorContainer = styled.div`
width: 60px;
display: flex;
justify-content: space-between;
`;

export const StatusContainer = styled.div`
display: flex;
justify-content: left;
align-items: center;
`;
export const TextArea = styled.textarea`
width: 100%;
  height: 150px;
  padding: 12px 20px;
  box-sizing: border-box;
  border: 1px solid var(--textLightGrey);
  border-radius: 10px;
  background-color: ${BASE_COLORS.white};
  font-size: 16px;
  resize: none;
  outline: 0;

`;

interface IconProps {
  isActive: boolean;
}

export const IconWrapper = styled.div`
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.15rem;
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DateWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: ${FONT_SIZE.xs};
  margin-bottom:5px;
`;


export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const CalendarIcon = styled(CalendarOutlined)`
  color: var(--primary-color);
  @media only screen and ${media.md} {
    color: var(--text-main-color);
  }
`;


export const CardText = styled(BaseTypography.Text)`
  margin-left: 0.5rem;
  line-height: 1;
  font-size: ${FONT_SIZE.xxs};
  color: var(--text-main-color);
  @media only screen and ${media.md} {
    font-size: ${FONT_SIZE.xs};
  }
`;

export const Description = styled(BaseTypography.Text)`
  margin-top: 0.625rem;
  font-size: ${FONT_SIZE.xxs};
  @media only screen and ${media.md} {
    font-size: ${FONT_SIZE.xs};
  }
`;

export const SpinIcon = styled(LoadingOutlined)`
  color: var(--primary-color);
  font-size: 50px;
`
export const RangePicker: any = styled(DatePicker.RangePicker)`
  width: 100%;
  background:${BASE_COLORS.transparent};
  border: 2px solid var(--primary-color);
  border-radius: 25px;
  box-shadow: none!important;
  padding-left: 10px;
  padding-right: 10px;
  
  .ant-picker-range-arrow {
    display:none;
  }

  &.ant-picker:hover{
    border-color: var(--primary-color);
    border-right-width: 2px;
  }

  &.ant-picker-range .ant-picker-active-bar{
    bottom: -2px;
    background: #EAEAEA!important;
  }

`;

export const AlignLeftCol = styled(Col)`
    padding-left:0px!important;
    display: flex;
    justify-content: left;
    align-items: center;
`;

export const AlignColWithPadding = styled(Col)`
    padding-top:50px;
    padding-bottom:50px;
`;

export const AlignRightCol = styled(Col)`
    display:flex;
    justify-content:end;
    align-items:center;
`;

export const AlignCenterCol = styled(AlignRightCol)`
    justify-content:center;
`;


export const AlignSpaceAroundCol = styled(AlignRightCol)`
    justify-content:space-around;
`;

export const AlignLeftBaseItem = styled(BaseForm.Item)`
    display: flex;
    justify-content: start;
`;

export const AlignRightBaseItem = styled(BaseForm.Item)`
    display: flex;
    justify-content: end;
`;

export const Header = styled(Layout.Header)`
  line-height: 1.5;

`;

const { Content } = Layout;

export const MainContent = styled(Content)`
  padding: ${LAYOUT.mobile.paddingVertical} ${LAYOUT.mobile.paddingHorizontal};
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media only screen and ${media.md} {
    padding: ${LAYOUT.desktop.paddingVertical} ${LAYOUT.desktop.paddingHorizontal};
  }
`;

export const LayoutMaster = styled(Layout)`
  width: 100vw;
  height: 100vh;
`;

export const LayoutMain = styled(Layout)`
  position:fixed;
  overflow-x: hidden;
  overflow-y: scroll;
  width: 100vw;
  height: 100vh;
`;

export const SuperhubLogo = styled.span`
    font-weight: ${FONT_WEIGHT.bold};
    font-size: ${FONT_SIZE.xxxl};
    color: var(--primary-color);
    margin: 5px;
`;

export const AdvisionLogo = styled.span`
    font-weight: ${FONT_WEIGHT.semibold};
    font-size: ${FONT_SIZE.md};
    color: var(--primary-color);
    margin: 5px;
`;

export const Sider = styled(Layout.Sider)`
  width: 75px;
  position: absolute;
  overflow: hidden;
  left: 0;
  z-index: 5;
  min-height: 100vh;
  max-height: 100vh;
  color: var(--text-secondary-color);
  justify-content:center;
`;

interface SubSiderProps {
  $isToggled: boolean
}

export const SubSider = styled(Layout.Sider) <SubSiderProps>`
  display: block;
  position: absolute;
  overflow: hidden;
  left: 75px;
  z-index: 5;
  min-height: 100vh;
  max-height: 100vh;
  background: ${BASE_COLORS.white};
  color: var(--text-secondary-color);
  border-right: 1.5px solid #D8D8D8;

  ${(props) =>
    props?.$isToggled == true &&
    css`
      left:75px;
    `}

    ${(props) =>
    props?.$isToggled == false &&
    css`
        display:none;
      `}
`;

export const SubMenuContainer = styled.div`
  display:flex;
  flex-direction: column;
  padding: 8%;
`;

export const ThemeText = styled.text`
  font-weight: ${FONT_WEIGHT.bold};
  color: var(--primary-color);
  font-size: ${FONT_SIZE.md};
`;

export const LinkText = styled.text`
  font-weight: ${FONT_WEIGHT.semibold};
  color: #C4C4C4;
  font-size: ${FONT_SIZE.xs};
  padding: 10px 15px;
  &:hover {
    color: var(--primary-color);
    cursor: pointer;
  }
  &:focus {
    color: var(--primary-color);
  }
`;

export const ItemsContainer = styled.div`
margin-top:60%;
`;

export const SiderItemDiv = styled.div`
background: ${BASE_COLORS.transparent};
color:${BASE_COLORS.white};
border: none;
width: 70%;
line-height: 0.3;
display: flex;
flex-direction: column;
padding-bottom:5px;
margin: 15%;
justify-content: center;
align-items: center;
&:hover {
    text-shadow: 1px 1px 2px #505050;
    cursor: pointer;
}
&.current, &:hover, &:focus {
    &::after {
        content: "◀";
        color: ${BASE_COLORS.white};
        position: absolute;
        left: 65px;
    }
}
`;

export const SiderItemText = styled.text`
font-size: 0.875rem;
line-height:1.5;
margin-top: 5%;
font-weight: ${FONT_WEIGHT.bold};
`;

export const SiderItemList = styled(Menu)`
margin-top:65%;
background: var(--primary-color);
color: ${BASE_COLORS.white};
justify-content: center;
align-items: center;
`;

export const StatisticCard = styled(BaseCard)`
display: flex;
justify-content: center;
line-height:25px;

.ant-card-body {
  flex-grow: 0!important;
  display: flex;
  justify-content: center;
  padding-left: 5px !important;
  padding-right: 5px !important;
  padding-top: 15px!important;
  padding-bottom: 15px!important;
  line-height:1;
`;

export const StatisticLoadingCard = styled(StatisticCard)`
@keyframes shine {
  to {
    background-position-x: -200%;
  }
}

.ant-card-body {
  background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
  border-radius: 5px;
  background-size: 200% 100%;
  animation: 1s shine linear infinite;
}
`;

export const StatisticCardV2 = styled(BaseCard)`
  .ant-card-body {
    padding: 15px !important;
  }
`;

export const OfferLetterCard = styled(StatisticCard)`
background-color: transparent;
box-shadow: none;
padding:0px!important;

.ant-card-body{
  display: flex;
  flex-direction: column;
  margin-left:10px;
}
`;

export const InformationCard = styled(StatisticCard)`
.ant-card-body {
justify-content: left;
flex-direction: column;
padding-left: 15px !important;
padding-right: 15px !important;
}    
`;

export const ReactMarkdownHeaderText = styled(ReactMarkdown)`
font-size: ${FONT_SIZE.md};
font-weight: ${FONT_WEIGHT.bold};
color: #505050;
`;

export const ReactMarkdownBodyText = styled(ReactMarkdownHeaderText)`
font-size: ${FONT_SIZE.xs};
`;

export const ReactMarkdownHighlightText = styled(ReactMarkdownHeaderText)`
font-size: ${FONT_SIZE.lg};
font-weight:${FONT_WEIGHT.extraBold};
`;


export const TransparentButton = styled(Button)`
width: 25px;
height: 25px;
border-color:${BASE_COLORS.transparent};
padding:0px!important;
margin-right: 15px;

:hover,
:focus {
  border-color:${BASE_COLORS.transparent};
}

`;

export const CommonMDEditor = styled(MDEditor)`
.w-md-editor-text-input::selection{
  background-color: rgba(237, 5, 121,0.5);
  -webkit-text-fill-color: ${BASE_COLORS.white};
}
`;

export const RenewalCenterTab = styled(Tab)`
  background: ${BASE_COLORS.transparent}!important;
  color: ${BASE_COLORS.darkgrey}!important;
  font-weight: ${FONT_WEIGHT.bold}!important;
  font-size: 0.85rem!important;
  text-transform: capitalize!important;
`;

export const VerticalTable = styled.table`
width: fit-content;
border-collapse:separate;
border-spacing: 0;
border-radius:10px;
border: 1px solid ${themeObject["light"].textLightGrey};
line-height: 25px;
font-size: 0.875rem;
margin-bottom: 15px;
overflow: hidden;

  thead {
    border-radius:10px 10px 0px 0px;
    background-color:${themeObject['light'].primary};
    color:${BASE_COLORS.white};
    font-size:${FONT_SIZE.xs};
  }
  tbody{
    background-color:${BASE_COLORS.white};
    color: var(--textLight);
  }
  td{
    padding:5px;
  }
`;

export const BoldTD = styled.td`
  font-weight:${FONT_WEIGHT.bold};
  color:#404040;
`;

export const AlignCenterTD = styled.td`
  text-align: center;
  max-width: 300px;
  margin-left:5px;
  color:#404040;
`;

export const AlignLeftTD = styled.td`
  text-align: left;
  max-width: 300px;
  margin-left:5px;
  color:#404040;
`;

export const SpacedTD = styled.td`
  padding: 0px 30px 0px 0px;
`;

export const LeftTH = styled.th`
  text-align: left;
`;

export const LetterSummary = styled(Box)`
  display: flex;
  justify-content: right;
  align-items: center;
`

export const RightBoxContainer = styled(Box)`
  display: flex;
  justify-content: right;
  align-items: center;
  width: auto;
`

export const LeftBoxContainer = styled(Box)`
  display: flex;
  justify-content: left;
  align-items: center;
  width: auto;
`

export const OptionBox = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid ${themeObject["light"].textLightGrey};
  border-radius: 20px;
  margin-bottom:5px;
  padding:15px;
`;

export const CancelSuggestionText = styled.text`
  color: blue;
  text-decoration: underline;
  cursor: pointer;
  font-size: ${FONT_SIZE.xs};
`
export const ProductItemContainer = styled.div`
  background-color: ${BASE_COLORS.white}!important;
  display:flex;
  flex-direction: column!important;
  padding: 5px;
  
`;

export const ProductCodeText = styled.div`
  color: #B0B0B0; 
  font-weight: ${FONT_WEIGHT.regular};
`;

export const OrderItemNameContainer = styled(ProductItemContainer)`
  background-color: ${BASE_COLORS.transparent}!important;
`;

export const FilterStatusBtn = styled.button`
  display: flex;
  justify-content: center;
  border: 1px solid ${themeObject["light"].primary};
  background: ${BASE_COLORS.white};
  color: ${themeObject["light"].primary};
  padding: 10px;
  width: 30vw;
  font-weight:${FONT_WEIGHT.bold};

  :hover, :focus{
    cursor: pointer;
    background: ${themeObject["light"].primary};
    color: ${BASE_COLORS.white};
    outline: none;
  }
`

export const EmailTemplateAlert = styled.div`
  margin-top: 10px;
  margin-bottom: 20px;
`;

export const EmailTemplateAlertMessage = styled.div`
  font-size: ${FONT_SIZE.xs};
`

export const TaskAlertMessage = styled(EmailTemplateAlertMessage)``;

export const AlertWithList = styled(Alert)`

  margin: 24px 0px 24px 0px;

  .ant-alert{
    margin-top:24px;
    display:-webkit-inline-box;
  }

  .ant-alert-message{
    display:flex;
  }

  .ant-alert-description{
    display:flex;
    flex-direction: column;
    align-items:baseline;
  }

  text{
    text-align:left;
    width: 85%;
  }
`;

export const noFoundDiv = styled.div`
  display: flex;
  width: 300px;
  justify-content: center;
`

export const StatusRadioGroup = styled(Radio.Group)`
display: flex;
justify-content: space-between;

.ant-radio-button-wrapper {
  border-radius:0px!important;
  text-align: center;
  width: 29vw;
  height: auto;
}
`

export const StatusRadioButton = styled(Radio.Button)`
  border: 1px solid ${themeObject['light'].primary};
  background: ${BASE_COLORS.white};
  color: ${themeObject['light'].primary};
  font-weight: ${FONT_WEIGHT.bold};
  

  :hover, :focus{
    cursor: pointer;
    background: ${themeObject["light"].primary};
    color: ${BASE_COLORS.white};
    outline: none;
  }
`;

export const StepWrapper = styled.div`
  display:flex;
  flex-direction:column;
  width: 45vw;
`;

export const StepHeaderText = styled.text`
  font-weight: ${FONT_WEIGHT.bold};
  color: ${themeObject['light'].primary};
  font-size: ${FONT_SIZE.md};
`;

export const BalanceText = styled.text`
    font-weight: ${FONT_WEIGHT.bold};
`;

export const ProfileStatisticCard = styled(BaseCard)`
min-width: 170px;

.ant-card-body {
  padding: 15px 15px !important;
}
`

export const AccountStatementAlert =styled(Alert)`
  width: 80vw;
`

export const AccountDetailCard = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 55vw;
  padding:5px;
`;

export const Hyperlink = styled.a`
  :link{
    color: #505050!important;
  }
`;